
import React, { useState, useEffect } from "react";
import styles from './slider.module.css';



// function Wrapper() {
class Slider extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      recentlyChangedSlider:false,
    }

    this.timeout = undefined;

    this.sectionWidth = 15;
 
    
    this.inputchange = this.inputchange.bind(this);
    this.handleinfo = this.handleinfo.bind(this);

  }

  handleinfo(){
    if(this.timeout != undefined){
      clearTimeout(this.timeout);
    }
    this.setState({
      recentlyChangedSlider: true
    })
    this.timeout = setTimeout(()=>{
      this.setState({
        recentlyChangedSlider: false
      })
    }, 2000)
  }

  inputchange(e){
    this.handleinfo()

    let val, mode;
    let sliderval = Number(e.target.value)+1; // there is no 0;

    if(sliderval <= this.props.limitdata){
      val = sliderval
      mode = 1;
      this.props.setOffsetAndMode(val, mode)
    }else if(sliderval > this.props.limitdata && sliderval < this.props.limitdata+this.sectionWidth/2){
      val = this.props.limitdata
      mode = 1;
      this.props.setOffsetAndMode(val, mode)
    }else{
      val = this.props.limitdata+this.sectionWidth
      mode = 0;
      this.props.setOffsetAndMode(val, mode)
    }
    console.log("val", val, "mode", mode)
    
  

  }
 
  render(){
    return (
      <div className={styles.wrapper}>
        <div className={styles.inputWrapper}>
          
          <input className={styles.range} disabled={this.props.fetching}  value={this.props.offsetValue-1} type="range" onInput={this.inputchange} onClick={this.handleinfo} onMouseMove={this.handleinfo} min={0} max={this.props.limitdata + this.sectionWidth - 1}></input>
          <div className={styles.rangeMarks}>
            
            
          <div className={styles.rangeMark} style={{
              left: "0%",
              // color: 'white'
            }}>
              {this.state.recentlyChangedSlider==true &&
                <>
                {this.props.mode==0 &&
                  <p className={styles.infoMarker}><b>stacking incoming packets infinitely</b></p>
                }
                {this.props.mode==1 &&
                  <p className={styles.infoMarker}><b>showing {this.props.offsetValue} most recent {this.props.offsetValue==1?"packet":"packets"}</b></p>
                  
                }
                </>
              
              }
          
              
            </div>
            
            

            <div className={styles.rangeMark} style={{
              left: "0%",
              opacity: this.props.mode==0?1:0.2
            }}>
              <img src="1.png"></img>
            </div>

            <div className={styles.rangeMark} style={{
              left: (this.sectionWidth*100)/(this.props.limitdata + this.sectionWidth-1)+"%",
              opacity: this.props.mode==0?0.2:1
              // color: this.props.mode==0?'white':'black'
            }}>
              <img src="2.png"></img>
            </div>

            <div className={styles.rangeMark} style={{
              left: "100%",
              opacity: this.props.mode==0?0.2:1
              // color: this.props.mode==0?'white':'black'
            }}>
              <img src="3.png"></img>
            </div>
            
            
            



          </div>
          
        </div>
  
      </div>
    );
  }
}

export default Slider;
