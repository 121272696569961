
import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BrowserView, MobileView, isMobile} from "react-device-detect";

import styles from './wrapper.module.css';
import Display from './display';
import MobileDisplay from './mobileDisplay';

import Slider from './slider';
import MobileNav from './mobileNav';
import InfoBox from './infoBox';
import InfoOverlay from './infoOverlay';

// import domtoimage from 'dom-to-image-more';


import socketIOClient from "socket.io-client";
const ENDPOINT = "http://127.0.0.1:8080";
// const LIMITDATA = 199; //null for no limit

// function Wrapper() {
class Wrapper extends React.Component{
  constructor(props){
    super(props)
    // console.log("ENVVV", process.env);
    // this.apiBase = 'http://localhost:8080';
    // this.apiBase = (process.env.NODE_ENV&&process.env.NODE_ENV=="development")?'http://localhost:8080':'';
    this.apiBase = (process.env.NODE_ENV&&process.env.NODE_ENV=="development")?'https://weleaktoo.com':'';

    this.apiStarter = '/api/v1/links';
    this.state = {
      archiveItems: [],
      realtimeItems: [],
      // total: null,
      biggestID: null,
      next: null,
      fetching: false,
      firstFetching: false,
      
      gotAllData: false,
      windowWidth: 1200,
      windowHeight: 800,
      currentDisplayOffset: isMobile?20:5, //if this is 0, the mode should be set to 1, else mode should be 0
      mode: 1, //0 = archive + socket, 1 = socket, 2 = recent [numForLiveFocusMode] socket 
      numForLiveFocusMode: 3,

      audioMode: "on",
      showinfo: true,
    }
    // this.test = process.env;
    this.getNext = this.getNext.bind(this);
    this.setOffsetAndMode = this.setOffsetAndMode.bind(this);
    this.updateWindow = this.updateWindow.bind(this);
    this.setSocketItemAppeared = this.setSocketItemAppeared.bind(this);
    this.deleteNotNeededRealTimeItems = this.deleteNotNeededRealTimeItems.bind(this);
    this.deleteNotNeededArchiveItems = this.deleteNotNeededArchiveItems.bind(this);
    // this.windowWidth = 1200;
    // this.windowHeight = 800;
    this.size = isMobile?200:600;
    this.limitdata = isMobile?20:199;
    this.mobileArchiveDisplay = 40;
    this.devicePixelRatio = window.devicePixelRatio;

    // this.myRef = React.createRef();
  }

  getNext(){
    let url = this.apiBase + this.state.next;
    console.log("[+] Fetching", url);
    this.setState({
      fetching: true
    })

    fetch(this.apiBase + this.state.next)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData);
        let next = resultData.nextMin;
        let newArchiveItems = resultData.data;

        this.setState({
          archiveItems: this.state.archiveItems.concat(newArchiveItems),
          next: next,
          fetching: false,
          gotAllData: resultData.status == "end"
        })
      }) 
  }


  deleteNotNeededArchiveItems(){
    if(this.state.archiveItems.length == 0)return 
    if(this.state.mode ==1 && (this.state.archiveItems.length+this.state.realtimeItems.length) > this.limitdata){
      console.log("removing archive data because too much!")
      let items = [...this.state.archiveItems]
      let needed = Math.max(0, this.limitdata-this.state.realtimeItems.length)
      items = items.splice(0, needed);
      this.setState({
        archiveItems:items
      })
    }
  }
  deleteNotNeededRealTimeItems(){
    // if the mode switches away from pile up mode we can reduce items to 
    // limitdata in total (assuming that socket might have risen higher than limit)
    if(this.state.mode ==1 && this.state.realtimeItems.length >= this.limitdata){
      console.log("removing socket data because too much!", this.state.realtimeItems.length-this.limitdata, this.limitdata)
      let items = [...this.state.realtimeItems]
      items = items.splice(this.state.realtimeItems.length-this.limitdata, this.limitdata);
      this.setState({
        realtimeItems:items
      })
    }
  }
  setOffsetAndMode(val, mode){

    

    

    let needData = val >= (this.state.archiveItems.length+this.state.realtimeItems.length);
    // if(val < (this.state.archiveItems.length+this.state.realtimeItems.length)){
      this.setState({
        currentDisplayOffset: val,
        mode: mode
      })
    if(needData&&this.state.mode==1&&!this.state.gotAllData&&(this.state.archiveItems.length+this.state.realtimeItems.length) < this.limitdata){
      this.getNext();
    }

    this.deleteNotNeededRealTimeItems();
    this.deleteNotNeededArchiveItems();
    
    
  }
  // this is to make sure only socket nodes that are really new 
  // are animated (black etc)
  setSocketItemAppeared(id){
    let items = [...this.state.realtimeItems];
    let item = items.find(d=>d.id==id)
    let i = items.findIndex(d=>d.id==id)
    item.hasAppeared = true;
    items[i] = item;
    this.setState({
      realtimeItems: items
    });
  }

  updateWindow(){
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    })
  }
  // useEffect(() => {
  componentDidMount(){
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    })
    window.addEventListener('resize', this.updateWindow)
    const socket = socketIOClient(this.apiBase,{transports: ['websocket']});
    
    socket.on("message", data => {

      console.log("hi", data)

      this.setState({
        realtimeItems: this.state.realtimeItems.concat([data]),
        // archiveItems: (this.state.realtimeItems.length+1)>=this.limitdata?[]:this.state.archiveItems //when we have enough socket item, we don't need archive anymore at all
      })
      this.deleteNotNeededRealTimeItems();
      this.deleteNotNeededArchiveItems();
    });
    let url = this.apiBase + this.apiStarter;
    console.log("[+] Fetching", url);
    this.setState({
      fetching: true,
      firstFetching: true
    })

    

    fetch(this.apiBase + this.apiStarter)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData);
        let next = resultData.nextMin;
        // let total = this.limitdata==null?resultData.data[0].id:this.limitdata; //now this wont give us the true length... but ultimately it should in theory
        let biggestID = resultData.data[0].id;
        let archiveItems = resultData.data;
        // archiveItems.map(item=>{
        //   if(item.type="txt"){
        //     item.content=item.content.split(" ").map(sentence=>sentence+=sentence.length).join(" ")
        //   }
        //   return item
        // })

        this.setState({
          archiveItems: archiveItems,
          // total: total,
          next: next,
          fetching: false,
          firstFetching: false,
          biggestID: biggestID
        });
        this.deleteNotNeededArchiveItems();

      }) 
  };

  render(){
    return (
      <div className={styles.app}>
        <BrowserView>
          {!this.state.firstFetching &&
          <>
          <TransformWrapper
            defaultScale={1 * this.state.windowWidth/(1.7*this.size)}
            defaultPositionX={-this.size/2 * this.state.windowWidth/(1.7*this.size) + this.state.windowWidth/2}
            defaultPositionY={-1*this.size/2 * this.state.windowWidth/(1.7*this.size) + this.state.windowHeight/2}
            options={{
              minScale: this.state.windowWidth/(1.7*this.size),
              maxScale: 19,
              limitToBounds:false,
              centerContent:false
            }}
            wheel={{
              step:100
            }}
            
          >
            <TransformComponent>
            <Display 
              archiveItems={this.state.archiveItems} 
              realtimeItems={this.state.realtimeItems}
              currentDisplayOffset={this.state.currentDisplayOffset}
              size={this.size}
              mode={this.state.mode}
              numForLiveFocusMode={this.state.numForLiveFocusMode}
              setSocketItemAppeared={this.setSocketItemAppeared}
              audioMode={this.state.audioMode}
              limitdata={this.limitdata}

            ></Display>
            </TransformComponent>
          </TransformWrapper>

          
          <Slider 
            // max={this.state.total} 
            fetching={this.state.fetching}
            setOffsetAndMode={this.setOffsetAndMode}
            offsetValue={this.state.currentDisplayOffset}
            mode={this.state.mode}
            numForLiveFocusMode={this.state.numForLiveFocusMode}
            limitdata={this.limitdata}
          ></Slider>
          </>}
  
          
          <InfoBox
            audioMode={this.state.audioMode}
            setAutioMode={(n)=>{this.setState({audioMode:n})}}
            open={()=>this.setState({showinfo:true})}
          ></InfoBox>

        </BrowserView>
        
        <MobileView>
            <MobileNav
              numForLiveFocusMode={this.state.numForLiveFocusMode}
              setOffsetAndMode={this.setOffsetAndMode}
              mode={this.state.mode}
              limitdata={this.limitdata}
  

            ></MobileNav>
            {/* {this.state.mode} */}
            {/* {this.state.windowWidth} */}

            <TransformWrapper
            defaultScale={1}
            defaultPositionX={-this.state.windowWidth/7}

              defaultPositionY={this.state.windowHeight/5}
              options={{
                minScale: 1,
                maxScale: 14,
                limitToBounds:false,
                centerContent:false
              }}
              wheel={{
                step:100
              }}
              
            >
              <TransformComponent>
                <MobileDisplay
                  currentDisplayOffset={this.state.currentDisplayOffset}
                  mode={this.state.mode}
                  archiveItems={this.state.archiveItems}
                  realtimeItems={this.state.realtimeItems}
                  numForLiveFocusMode={this.state.numForLiveFocusMode}
                  mobileArchiveDisplay={this.mobileArchiveDisplay}
                  setSocketItemAppeared={this.setSocketItemAppeared}
                  size={this.size}
                  audioMode={this.state.audioMode}
                  limitdata={this.limitdata}




                ></MobileDisplay>
              </TransformComponent>
            </TransformWrapper>
            <InfoBox
            audioMode={this.state.audioMode}
            setAutioMode={(n)=>{this.setState({audioMode:n})}}
            open={()=>this.setState({showinfo:true})}
          ></InfoBox>

          
        </MobileView>

        {this.state.fetching &&
          <p className={styles.dataFetchWarning}><b>fetching data...</b></p>
        }   


        {/* INFO SCREEN */}

        {this.state.showinfo && 
          <InfoOverlay
            fetching={this.state.firstFetching}
            close={()=>this.setState({showinfo:false})}
          ></InfoOverlay>
        }


      </div>
    );
  }
}

export default Wrapper;
