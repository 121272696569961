
import React, { useState, useEffect } from "react";
import styles from './mobileNav.module.css';



function MobileNav(props) {

  const handleChange = (e) => {
    console.log(e.target.value);
    props.setOffsetAndMode(props.limitdata, e.target.value);
  }  
  return(
    <div className={styles.navWrapper}>
        {/* <div><div><p>Display archived packets.</p></div></div>
        <div><div><p>Real-time: Display packets as they are captured.</p></div></div>
        <div><div><p>Real-time: Display the {props.numForLiveFocusMode} most recently captured packets</p></div></div> */}
       
        {/* <div onClick={()=>{handleTheClick(0)}} className={props.mode==0?styles.selected:""}><div><p>Archive<br></br>+<br></br>Real-time</p></div></div>
        <div onClick={()=>{handleTheClick(1)}} className={props.mode==1?styles.selected:""}><div><p>Real-time</p></div></div>
        <div onClick={()=>{handleTheClick(2)}} className={props.mode==2?styles.selected:""}><div><p>Real-time<br></br>({props.numForLiveFocusMode} most recent)</p></div></div> */}
        <div className={styles.rangeWrapper}>
          <input onChange={handleChange} type="range" min={0} max={1} ></input>
          
        </div>

        <div className={styles.slideIcons} style={{
              left: "0%",
              // color: 'white'
            }}>
           
                {props.mode==0 &&
                  <p className={styles.infoMarker}><b>stacking incoming packets infinitely</b></p>
                }
                {props.mode==1 &&
                  <p className={styles.infoMarker}><b>showing {props.limitdata} most recent {props.offsetValue==1?"packet":"packets"}</b></p>
                  
                }
              
          
              
            </div>
            


        <div className={styles.slideIcons}>
          
          <div style={{
            left: "0%"
          }}>
            <img src="1.png"></img>
          </div>
          <div style={{
            left: "100%"
          }}>
            <img src="2.png"></img>
          </div>


        </div>

    </div>
  )
}

export default MobileNav;
