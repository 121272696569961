
import React, { useState, useEffect } from "react";
import styles from './display.module.css';
import SimplexNoise from 'simplex-noise';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
    // simplex = new SimplexNoise(Math.random),
    // value2d = simplex.noise2D(x, y);

const Item = ({ children, animate, afterEntered , ...props }) => {
  const nodeRef = React.useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      timeout={0}
      onEnter={()=>{
        // document.body.style.backgroundColor = "black"
        // document.body.style.transition = "all 0s"
      }}
      onEntering={()=>{
        // document.body.style.backgroundColor = "transparent"
        // document.body.style.transition = "all 0.8s ease 0.1s"

      }}
      onEntered={()=>{
        if(animate){
          afterEntered();
        }
      }}
      // classNames="packetItem"
      classNames={{
        enter: animate?styles.itemEnter:"",
        enterActive: animate?styles.itemEnterActive:"",
        enterDone: animate?styles.itemEnterDone:"",
      }}
      {...props}
    >
      <span ref={nodeRef}>
        {children}
      </span>
    </CSSTransition>
  );
};

// function Wrapper() {
class Display extends React.Component{
  constructor(props){
    super(props)
    this.displayArchiveItems = this.displayArchiveItems.bind(this);
    this.displaySocketItems = this.displaySocketItems.bind(this);

    this.simplex = new SimplexNoise(1);
    
  }

  displayDot(item, x, y){
    
    // {id},{unixts}, {noise1}, {noise2}
    return <div key={item.id} style={{
      width: 10,
      height: 10,
      backgroundColor: "black",
      left:x, 
      top:y
    }}></div>
  }
  displayItem(item, group){
    
    let ranx = Math.random()*4000;
    let rany = Math.random()*4000;
    let id = item.id;
    let unixts = new Date(item.ts).getTime()
    let factor = this.props.size/2;
    let x = factor*(this.simplex.noise2D(id/10, 0)+1);
    let y = factor*(this.simplex.noise2D(unixts/100, 100)+1);

    if(item.type == "png" || item.type == "jpg" || item.type == "gif"){
      return <img className={styles.item} key={item.id} src={item.link} style={{left:x, top:y, width:400, pointerEvents:'none', transform:"scale(0.3)"}}></img>
    }else if(item.type == "txt"){
      return <p className={styles.item} key={item.id} style={{left:x, top:y, pointerEvents:'none', fontSize: "1em", fontSize: "12px", transform:"scale(0.15)", transformOrigin:"top left"}}>{item.content}</p>
    }else if(item.type == "mp3"){
      return <audio className={styles.item} autoPlay={this.props.audioMode=="on"||this.props.audioMode=="auto"} controls key={item.id} src={item.link} style={{pointerEvents:'all', width:400, left:x, top:y, transform: "scale(0.15)"}}></audio>
    }
  }


  

  displaySocketItems(num){
    // only the latest:
    // if(this.props.realtimeItems.length<1) return
    // return this.displayItem(this.props.realtimeItems[this.props.realtimeItems.length-1], 'archive')
    console.log("displaying", num, "socketItems")
    // return <></>


      return this.props.realtimeItems.filter((d,i)=>{
        if(this.props.mode!=1){
          return true
        }else if(i >= (this.props.realtimeItems.length-num)){
          console.log("hi", i)
          return true
        }
      }).map((d, i)=>{
        console.log("render", i)
        return <Item key={d.id} animate={d.hasAppeared==undefined} afterEntered={()=>{this.props.setSocketItemAppeared(d.id)}}>
            {this.displayItem(d, 'socket')}
        </Item>
        // return <p key={d.id}>socket</p>
      });
  }

  displayArchiveItems(num){
    // only the latest:
    // if(this.props.realtimeItems.length<1) return
    // return this.displayItem(this.props.realtimeItems[this.props.realtimeItems.length-1], 'archive')
    console.log("displaying", num, "archiveItems")

    // return <></>

    // return this.props.archiveItems.filter(d=>d.id>1).map(d=>{

    return this.props.archiveItems.filter((d, i)=>i<num).map(d=>{
      // console.log(d)
      // console.log("displaying archiveItem", this.props.threshold, d)
      return <div key={d.id}>
          {this.displayItem(d, 'archive')}
      </div>
      // return (<CSSTransition 
      //                 key={d.id}
      //                 in
      //                 timeout={500}
      //                 classNames="item"
      //             >
      //               
                    
      //             </CSSTransition>
      //         )
      // return <p key={d.id}>archive</p>
    });
  }

  render(){
    return (
      <>
        {/* <p>thresh: {this.props.threshold}</p> */}
       
        <div className={styles.packetWrapper}>



            <div className={styles.packetInnerWrapper} className={styles.displayWrapper}>
              <TransitionGroup timeout={0} component={null}>
                {/* <CSSTransition timeout={0}><span>a</span></CSSTransition><CSSTransition timeout={0}><span>a</span></CSSTransition> */}
                {this.displaySocketItems( Math.min( this.props.currentDisplayOffset, this.props.realtimeItems.length ) )}
              </TransitionGroup>
                {this.displayArchiveItems( 
                  Math.max( 0, this.props.currentDisplayOffset - this.props.realtimeItems.length) 

                )}
            </div>
          
          
  


      </div>
     </>
    );
  }
}

export default Display;
