
import React, { useState, useEffect } from "react";
import styles from './infoBox.module.css';

import { isMobile} from "react-device-detect";


function InfoBox(props) {
  // const [audioMode, setAudioMode] = useState("auto");

  const handleCycle=()=>{
    console.log("hi")
    if(props.audioMode=="auto"){
      props.setAutioMode("on")
    }else if(props.audioMode=="on"){
      props.setAutioMode("off")
    }else{
      props.setAutioMode("on")
    }
  }
  return(
    <div className={styles.infoWrapper}>
      <p onClick={()=>props.open()}>Info</p>
      {!isMobile&& 
        <p className={styles.audioButton} onClick={handleCycle}>sound: <u>{props.audioMode}</u></p>
      }
      
    </div>
  )
}

export default InfoBox;
