import React from 'react';

import ReactDOM from 'react-dom';
import './index.css';
import Wrapper from './wrapper';
import Wrapper2 from './wrapper2';


ReactDOM.render(
  <React.StrictMode>
    <Wrapper />
  </React.StrictMode>,
  document.getElementById('root')
);


