
import React, { useState, useEffect, useRef } from "react";
import styles from './mobileDisplay.module.css';
import SimplexNoise from 'simplex-noise';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const Item = ({ children, animate, afterEntered , ...props }) => {
  const nodeRef = React.useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      timeout={0}
      onEnter={()=>{
        // document.body.style.backgroundColor = "black"
        // document.body.style.transition = "all 0s"
      }}
      onEntering={()=>{
        // document.body.style.backgroundColor = "transparent"
        // document.body.style.transition = "all 0.8s ease 0.1s"

      }}
      onEntered={()=>{
        if(animate){
          afterEntered();
        }
      }}
      // classNames="packetItem"
      classNames={{
        enter: animate?styles.itemEnter:"",
        enterActive: animate?styles.itemEnterActive:"",
        enterDone: animate?styles.itemEnterDone:"",
      }}
      {...props}
    >
      <div ref={nodeRef}>
        {children}
      </div>
    </CSSTransition>
  );
};


function MobileDisplay(props) {  
  const simplex = useRef(new SimplexNoise(1));
  const [archiveCount, setArchiveCount] = useState(0);
  const timeout = useRef(null);
  
  useEffect(()=>{
    if(props.mode == 0 && archiveCount<props.mobileArchiveDisplay){
      timeout.current = setTimeout(()=>{
        setArchiveCount(c=>c+=1);
      }, 500);
    }else if(props.mode != 0){
      setArchiveCount(0)
    }
    return ()=>{
      clearTimeout(timeout.current)
    }
  }, [props.mode, archiveCount])
 
  const displayItem = (item, group) => {
    
    let id = item.id;
    let unixts = new Date(item.ts).getTime()
    let factor = props.size/2;
    let x = props.size*(simplex.current.noise2D(id/10, 0)+1);
    let y = props.size*(simplex.current.noise2D(unixts/100, 100)+1);

    if(item.type == "png" || item.type == "jpg" || item.type == "gif"){
      return <img className={styles.item} key={item.id} src={item.link} style={{left:x, top:y, width:400, pointerEvents:'none', transform:"scale(0.3)"}}></img>
    }else if(item.type == "txt"){
      return <p className={styles.item} key={item.id} style={{left:x, top:y, pointerEvents:'none', fontSize: "1em", fontSize: "12px", transform:"scale(0.15)"}}>{item.content}</p>
    }else if(item.type == "mp3"){
      return <audio className={styles.item} autoPlay={props.audioMode=="on"||props.audioMode=="auto"} controls key={item.id} src={item.link} style={{pointerEvents:'all', left:x, top:y, width:400, transform: "scale(0.15)"}}></audio>
    }
  }

  const displaySocketItems = (num) => {
    // only the latest:
    // if(this.props.realtimeItems.length<1) return
    // return this.displayItem(this.props.realtimeItems[this.props.realtimeItems.length-1], 'archive')
    console.log("displaying", num, "socketItems")
    // return <></>


      return props.realtimeItems.filter((d,i)=>{
        if(props.mode!=1){
          return true
        }else if(i >= (props.realtimeItems.length-num)){
          console.log("hi", i)
          return true
        }
      }).map((d, i)=>{
        console.log("render", i)
        return <Item key={d.id} animate={d.hasAppeared==undefined} afterEntered={()=>{props.setSocketItemAppeared(d.id)}}>
            {displayItem(d, 'socket')}
        </Item>
        // return <p key={d.id}>socket</p>
      });
  }

  const displayArchiveItems = (num) => {
    // only the latest:
    // if(this.props.realtimeItems.length<1) return
    // return this.displayItem(this.props.realtimeItems[this.props.realtimeItems.length-1], 'archive')
    console.log("displaying", num, "archiveItems")

    // return <></>

    // return this.props.archiveItems.filter(d=>d.id>1).map(d=>{

    return props.archiveItems.filter((d, i)=>i<num).map(d=>{
      // console.log(d)
      // console.log("displaying archiveItem", this.props.threshold, d)
      return <div key={d.id}>
          {displayItem(d, 'archive')}
      </div>
      // return (<CSSTransition 
      //                 key={d.id}
      //                 in
      //                 timeout={500}
      //                 classNames="item"
      //             >
      //               
                    
      //             </CSSTransition>
      //         )
      // return <p key={d.id}>archive</p>
    });
  }
  return(
    <div className={styles.packetWrapper}>
      {/* {displayArchivedItems()} */}
      {/* {archiveCount} */}

        <div className={styles.packetInnerWrapper} className={styles.displayWrapper}>
              <TransitionGroup timeout={0} component={null}>
                {/* <CSSTransition timeout={0}><span>a</span></CSSTransition><CSSTransition timeout={0}><span>a</span></CSSTransition> */}
                {displaySocketItems( Math.min( props.currentDisplayOffset, props.realtimeItems.length ) )}
              </TransitionGroup>
                {displayArchiveItems( 
                  Math.max( 0, props.currentDisplayOffset - props.realtimeItems.length) 

                )}
            </div>
          
    </div>
  )

}

export default MobileDisplay;
