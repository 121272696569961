
import React, { useState, useEffect } from "react";
import styles from './infoOverlay.module.css';



function InfoBox(props) {
  return(
    <div className={styles.infoinfoOverlayWrapper}>
      <div className={styles.wrapper}>
       
        <div className={styles.content}>
          <h3 className={styles.headline}>We Leak Too (2020)</h3>
          <h6 className={styles.names}>Leon Eckert & Vytas Jankauskas</h6>
          <p>
          This work consists of two parts, a voice assistant and this website. 
          </p>
          <p>
          The voice assistant is currently on display at <a target="_blank" href="http://we-link.chronusartcenter.org/sideways/">Chronus Art Center</a> in Shanghai, China. While ‘assisting’ everyday queries of exhibition visitors, it intercepts the communication between other artworks that are connected to the local network in the exhibition space. As it listens to the information transmitted, the device loudly recites the content through an integrated speaker.</p>
          <p>
          Simultaneously, it sends captured content, text and images, to its own server. This content, next to recordings of the assistant’s voice, is displayed on this website in real-time.
          </p>
          {props.fetching &&
            <p className={styles.fetching}>Hold on, fetching recent data...</p>
          }
          {!props.fetching &&
            <p className={styles.close} onClick={()=>props.close()}>See the work --></p>
          }
          
        </div>
        
      </div>
    </div>
  )
}

export default InfoBox;
